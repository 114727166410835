import request from '@/utils/request';

/*get the impressum*/
export function getTheImpressum() {
	return request({
		url: '/impressum',
		method: 'get',
	});
}

/*get the datenschutz*/
export function getTheDatenschutz() {
	return request({
		url: '/datenschutz',
		method: 'get',
	});
}

/*search - Funktion for the overview with all available options*/
export function getContentsOfType(type_id,query,langcode = "de") {
	return request({
		url: 'contenttypes/contents/'+type_id+'/de',
		method: 'get',
		params: query,
	});
}

/*search - Funktion for the overview with all available options*/
export function search(query) {
	return request({
		url: '/content/search',
		method: 'get',
		params: query,
	});
}